import { ReactNode } from "react";
import { AuthProvider, useAuthContext } from "@/contexts";
import { AppLayout } from "@/layouts";
import { APP_NAME } from "@/config/common";
import {
  Alert,
  BottomNav,
  BottomSheet,
  Button,
  Container,
  Img,
  Overlay,
  Spinner,
  Text,
  Title
} from "@/components/common";
import Head from "next/head";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { useQuery } from "@tanstack/react-query";
import { firebaseFetchRecentContracts, firebaseGetDownloadUrl } from "@/utils/firebase";
import profileImg from "@/assets/profile.svg";
import infoCircleImg from "@/assets/info-circle.svg";
import { ContractItem } from "@/components/page";
import numeral from "numeral";

export default function Home() {
  const router = useRouter();
  const { user } = useAuthContext();

  const recentContracts = useQuery({
    queryKey: ["fetch-recent-contracts"],
    queryFn: async () => {
      const res = await firebaseFetchRecentContracts();
      return res.data;
    }
  });

  return (
    <>
      <Head>
        <title>Home - {APP_NAME}</title>
      </Head>

      <AppLayout title={APP_NAME} loading={recentContracts.isFetching}>
        <AppLayout.Header>
          {recentContracts.error && (
            <Alert type="error">
              <Text align="center">{recentContracts.error.message}</Text>
            </Alert>
          )}
        </AppLayout.Header>

        <AppLayout.Body className="flex flex-col justify-safe-center overflow-auto gap-8">
          <Container className="flex flex-col gap-4">
            <div className="text-center">
              <div className="rounded-full grid place-items-center w-14 h-14 mx-auto overflow-hidden bg-black">
                <Img
                  src={user.pictureUrl ? firebaseGetDownloadUrl(user.pictureUrl) : profileImg}
                  className="w-full"
                  alt="Profile"
                />
              </div>
            </div>

            <Text className="text-gray-500" align="center">
              Welcome <strong className="text-black">{user.name.split(" ")[0]}</strong>
            </Text>
          </Container>

          <Container className="flex flex-col gap-4">
            <Text align="center">My Recent Contracts</Text>

            {recentContracts.isFetching ? (
              <Spinner>Please wait...</Spinner>
            ) : recentContracts.data && recentContracts.data.length > 0 ? (
              <>
                {recentContracts.data.map((contract) => (
                  <ContractItem key={contract.id} userId={user.id} contract={contract} />
                ))}

                <Link href="/app/contracts?overlay=new">
                  <Button variant="secondary" flex>
                    New Contract
                  </Button>
                </Link>
              </>
            ) : recentContracts.data && recentContracts.data.length === 0 ? (
              <>
                <Text align="center">You haven&apos;t created any contract yet</Text>

                <Link href="/app/contracts?overlay=new-contract">
                  <Button variant="secondary" flex>
                    New Contract
                  </Button>
                </Link>
              </>
            ) : null}
          </Container>

          <Container className="grid grid-cols-1 gap-4">
            <div className="flex flex-col gap-4 bg-black rounded-xl p-4 shadow-2xl text-white">
              <div className="flex items-center justify-between">
                <span className="w-[12px]" />

                <Text align="center" className="text-gray-400">
                  Contract Payouts/Refunds
                </Text>

                <Image
                  className="hover:cursor-pointer"
                  src={infoCircleImg}
                  alt="Info circle icon"
                  onClick={() => {
                    return router.push({
                      query: {
                        ...router.query,
                        help: "payouts"
                      }
                    });
                  }}
                />
              </div>

              <Title className="leading-tight flex-1" size="lg" align="center">
                {user.country.currency} {numeral(user.walletAmount || 0).format("0,0.00")}
              </Title>

              <hr className="w-1/2 max-w-[100px] mx-auto border-gray-400" />

              <Link href="/app/withdraw">
                <Button flex>Withdraw</Button>
              </Link>
            </div>
          </Container>
        </AppLayout.Body>

        <AppLayout.Footer className="flex flex-col gap-4">
          <BottomNav layout="general" active="home" />
        </AppLayout.Footer>
      </AppLayout>

      <Overlay show={router.query.help === "payouts"} placement="bottom" onClose="/app">
        <BottomSheet title="Contract Payout/Refund" back="/app">
          <Text>
            <strong>As a seller</strong>, when you complete contracts, the amounts are added to this balance, which you
            can withdraw at anytime.
          </Text>

          <Text>
            <strong>As a buyer</strong>, when your contract is cancelled, your payment is added to this balance, which
            you can withdraw at anytime.
          </Text>
        </BottomSheet>
      </Overlay>
    </>
  );
}

Home.decorator = function (page: ReactNode) {
  return <AuthProvider>{page}</AuthProvider>;
};
